import React from 'react';
import { Link } from 'react-router-dom';

export default function MenuWidget({ data }) {
  return (
    <ul className="cs_menu_widget cs_mp0">
      {data?.map((item, index) => (
        <li key={index} style={{ marginBottom: '.5em' }}>
          <Link to={item.href} style={{ "fontSize": "16px", "lineHeight": "1.5", "fontWeight": "400", "color": "#fff" }}>{item.title}</Link>
        </li>
      ))}
    </ul>
  );
}
