import Spacing from '../Spacing';
import Lottie from 'react-lottie';
import loading from '../../loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const diseases = [
    {
        title: 'All types of Cancers and Tumour'
    },
    {
        title: 'Chronic Kidney Disease'
    },
    {
        title: 'Diabetes and Hypertension in a single person'
    },
    {
        title: 'Rheumatoid Arthritis'
    },
    {
        title: 'All types of Heart Diseases'
    },
    {
        title: 'COPD'
    },
    {
        title: 'Liver Cirrhosis'
    },
    {
        title: 'Cerebral vascular accident (all conditions)'
    },
    {
        title: 'History of major cardiac surgeries'
    },
    {
        title: 'Multiple Sclerosis'
    },
];

export default function StepThree({
    error,
    loading,
    setIsHealthy,
    otherDiseases,
    setOtherDiseases,
    goToStepFour,
    goToPreTwo,
    user
}) {

    return (
        <>
            <div className="cs_list cs_style_1">
                <h2 className="cs_list_title cs_medium cs_fs_24" style={{ paddingLeft: '0px' }}>
                    Health conditions
                </h2>
                <ul>
                    <div className='row'>
                        {diseases?.map((item, index) => (
                            <li key={index} className='col-12 col-lg-6'>
                                {item.title && (
                                    <p className="cs_medium cs_heading_color mb-0">{item.title}</p>
                                )}
                            </li>
                        ))}
                    </div>
                </ul>
            </div>
            {/* <Spacing md="20" /> */}
            <div className="col-lg-12">
                <div className="cs_radio_group row" style={{ padding: 'calc(var(--bs-gutter-x)* .5)' }}>
                    <div className="cs_radio_wrap">
                        <input
                            className="cs_radio_input"
                            type="radio"
                            name="isHealthy"
                            id="yes"
                            value={'YES'}
                            checked={true}
                            onChange={(a) => setIsHealthy(a.target.value)}
                        />
                        <label className="cs_radio_label" htmlFor="yes" style={{ color: '#D2538C' }}>
                            I / We confirm that I / We do not have history of any of these 10 conditions in the past or I / We are not diagnosed with any of these conditions currently.
                        </label>
                    </div>
                    <div className="cs_radio_wrap">
                        <input
                            className="cs_radio_input"
                            type="radio"
                            name="isHealthy"
                            id="no"
                            value={'NO'}
                            // checked={!user?.is_healthy}
                            onChange={(a) => setIsHealthy(a.target.value)}
                        />
                        <label className="cs_radio_label" htmlFor="no" style={{ color: '#D2538C' }}>
                            I / We do have history of one or many of these 10 conditions in the past.
                        </label>
                    </div>
                </div>
                <div className="cs_height_10" />
            </div>
            <div className="col-lg-12">
                <textarea
                    type="text"
                    style={{ color: '#D2538C' }}
                    className="cs_form_field"
                    onChange={(a) => setOtherDiseases(a.target.value)}
                    placeholder="Enter the details if you have any other diseases..."
                >{otherDiseases}</textarea>
                <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div style={{ color: '#D2538C' }}>
                By proceeding, I affirm that all information given is correct and truthful to the best of my knowledge.
            </div>
            <Spacing md="20" />
            {loading ? (
                <button className="cs_btn cs_style_1">
                    <span>Continue</span>
                    <Lottie options={defaultOptions} width={45} />
                </button>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <button className="cs_btn cs_style_1" onClick={goToPreTwo} style={{ marginRight: '10px' }}>
                        <span>Previous</span>
                    </button>
                    <button className="cs_btn cs_style_1" onClick={goToStepFour}>
                        <span>Continue</span>
                        <i>
                            <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                            <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                        </i>
                    </button>
                </div>
            )}
            {error &&
                <div style={{ color: 'red', fontSize: '12px' }}>
                    Please complete the details
                </div>
            }
        </>
    );
}