import Spacing from '../Spacing';
import AppointmentSectionStyle3 from '../Section/AppointmentSection/AppointmentSectionStyle3';
import Section from '../Section';

export default function Profile() {

    return (
        <>
            <Spacing md="170" />
            <Section>
                <AppointmentSectionStyle3 title="Profile & Subscriptions" />
            </Section>
        </>
    );
}