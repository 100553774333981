import React from 'react';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import IconBoxStyle4 from '../../IconBox/IconBoxStyle4';
import { useLocation } from 'react-router-dom';

export default function DepartmentSectionStyle1({
  sectionTitle,
  sectionTitleUp,
  data,
  handleGetStarted
}) {

  const location = useLocation();
  const currentUrl = `${location.pathname}`;

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-xl-4" style={{ textAlign: 'center' }}>
          <SectionHeading title={sectionTitle} titleDown={sectionTitleUp} />
          {currentUrl != '/onboarding' &&
            <div className={`cs_btn cs_style_1`} onClick={handleGetStarted} style={{ marginTop: '50px' }}>
              <span>Get Started / Login</span>
              <i>
                <img src="images/icons/arrow_white.svg" alt="Icon" />
                <img src="images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </div>
          }
          <Spacing md="72" lg="50" />
        </div>
        {data?.map((item, index) => (
          <div className="col-md-6 col-xl-4" key={index}>
            <IconBoxStyle4 {...item} />
          </div>
        ))}
      </div>

      {currentUrl != '/benefits' &&
        <div className='text-end mt-3'>
          <a href='/benefits' style={{ fontSize: '16px', color: '#D2538C', fontWeight: 'b' }}>
            <span>View More {">"}</span>
          </a>
        </div>
      }
    </div>
  );
}
