import React from 'react';
import parse from 'html-react-parser';
import Spacing from '../Spacing';

export default function SectionHeading({
  title = '',
  titleUp = '',
  titleDown = '',
  subTitle = '',
  center,
}) {
  return (
    <div
      className={`cs_section_heading cs_style_1 ${center ? 'text-center' : ''}`}
    >
      {title && (
        <div className='d-flex align-items-center'>
          {/* <img src={'/images/icons/back.svg'} style={{ width: '22px' }} alt='back' /> */}
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h2 className="cs_section_title cs_fs_50 m-0">{parse(title)}</h2>
          </div>
        </div>
      )}
      {titleDown && (
        <>
          <Spacing md="5" />
          <h3 className="cs_section_subtitle cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_24">
            {parse(titleDown)}
          </h3>
        </>
      )}
      {subTitle && (
        <>
          <Spacing md="25" />
          <p className="m-0">{parse(subTitle)}</p>
        </>
      )}
    </div>
  );
}
