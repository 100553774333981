import React from 'react';
import parser from 'html-react-parser';
import { Link } from 'react-router-dom';

export default function IconBoxStyle6({ name, address, city }) {
  return (
    <div className="cs_iconbox cs_style_7 " style={{ padding: '40px', minHeight: '250px' }}>

      <h2 className="cs_iconbox_title cs_fs_32">{parser(name)}</h2>
      <p className="cs_iconbox_subtitle m-0" style={{ fontSize: '17px' }}>{parser( address )}</p>
      <p className="cs_iconbox_subtitle text-uppercase m-0 mt-1" style={{ fontSize: '17px' }}>{parser( city )}</p>
     
    </div>
  );
}
