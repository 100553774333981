import React from 'react';
import { Icon } from '@iconify/react';

export default function PricingHorizontal({
    name,
    description,
    price,
    period,
    all_benefits,
    is_popular,
    openPopUp,
    item,
    duration
}) {
    return (
        <div className="cs_pricing_card cs_style_1 cs_radius_20 overflow-hidden">
            <div className="cs_pricing_card_head cs_accent_bg cs_white_color" style={{ textAlign: 'center' }}>
                <h3 className="cs_white_color cs_fs_18 cs_semibold">
                    <span className="cs_accent_bg">{name}</span>
                    {is_popular == 1 && (
                        <span className="cs_heading_color cs_normal">Popular</span>
                    )}
                </h3>
                {/* {description && <p className="cs_fs_10">{description}</p>} */}
                <h2 className="cs_white_color mb-0 cs_fs_24 cs_semibold">
                    ₹ {Math.trunc(Number(price))}
                    <span className="cs_fs_18">
                        /{duration == 30 ? 'month' : 'year'}
                    </span>
                </h2>
            </div>
            <div className="cs_pricing_card_body">
                <ul className="cs_pricing_card_feature cs_fs_15 cs_heading_color">
                    <div className='row' style={{ paddingLeft: '15px' }}>
                        {all_benefits?.map((item, index) => (
                            <li key={index} className='col-lg-6'>
                                <i>
                                    <Icon icon="fa6-solid:circle-check" />
                                </i>
                                {item}
                            </li>
                        ))}
                    </div>
                </ul>
                <div className="cs_pricing_card_btn text-center">
                    <button className={`cs_btn cs_style_1`} onClick={() => openPopUp(item)}>
                        <span>Choose Plan</span>
                        <i>
                            <img src="images/icons/arrow_white.svg" alt="Icon" />
                            <img src="images/icons/arrow_white.svg" alt="Icon" />
                        </i>
                    </button>
                </div>
            </div>
        </div>
    );
}
