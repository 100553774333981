import { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import Lottie from 'react-lottie';
// import loading from '../../loading.json';

// const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: loading,
//     rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice'
//     }
// };

const steps = [
    {
        label: 'Who is this insurance policy for?',
        description: `Please provide your details and those of your family members to determine who will be covered by this insurance policy.`,
    },
    {
        label: 'Health conditions',
        description:
            'Please disclose any existing health conditions to accurately assess your insurance needs.',
    },
    // {
    //     label: 'Health conditions',
    //     description: `Please provide information on any specific illnesses or health conditions you may have.`,
    // },
    {
        label: 'Indicate if you currently hold any form of insurance coverage',
        description: `Please provide information about any existing insurance policies you currently hold.`,
    },
    {
        label: 'Choose your plan and enjoy free health insurance cover of your choicest sum'
    },
];

export default function VerticalLinearStepper({ index, loading, goToStep }) {
    const [activeStep, setActiveStep] = useState(index);

    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    return (
        <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical" sx={{
                '& .MuiStepIcon-root.Mui-active, & .MuiStepIcon-root.Mui-completed': {
                    color: '#D2538C', // Changes the color of the active and completed icons
                },
                '& .MuiStepIcon-text': {
                    fill: '#fff', // Changes the text color inside the icon to white
                },
            }}>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel>
                            <div style={{ fontSize: '13px' }}>{step.label}</div>
                        </StepLabel>
                        <StepContent>
                            <Typography>
                                <div style={{ fontSize: '13px' }}>{step?.description}</div>
                            </Typography>
                            {/* <Box sx={{ mb: 2 }}>
                                <div>
                                    {loading ? (
                                        <button className="cs_btn cs_style_1 mt-4">
                                            <span>Continue</span>
                                            <Lottie options={defaultOptions} width={45} />
                                        </button>
                                    ) : (
                                        <button className="cs_btn cs_style_1 mt-4" onClick={goToStep}>
                                            <span>Continue</span>
                                            <i>
                                                <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                                                <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                                            </i>
                                        </button>
                                    )}
                                </div>
                            </Box> */}
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {/* {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Reset
                    </Button>
                </Paper>
            )} */}
        </Box>
    );
}