// export const WEBSITE_API_URL = 'http://getafixmd.test/api/'
// export const WEBSITE_URL = 'http://getafixmd.test/'

export const WEBSITE_API_URL = 'https://getafixmd.howincloud.com/public/api/'
export const WEBSITE_URL = 'https://getafixmd.howincloud.com/public/'


export const GET_PLANS_URL = WEBSITE_API_URL  + 'get-all-plans';
export const GET_BOOKINGS_URL = WEBSITE_API_URL  + 'get-bookings';
export const SEND_OTP_URL = WEBSITE_API_URL  + 'send-otp';
export const UPDATE_USER_DATA_URL = WEBSITE_API_URL  + 'update-user-data';
export const VERIFY_OTP_URL = WEBSITE_API_URL  + 'verify-otp';
export const ADD_POLICY_DETAILS_URL = WEBSITE_API_URL  + 'add-exist-policy';
export const ADD_FAMILY_DETAILS_URL = WEBSITE_API_URL  + 'add-insurance-type';
export const ADD_HEALTH_CONDITION_URL = WEBSITE_API_URL  + 'add-health-condition';
export const ADD_SPECIFIC_ILLNESS_URL = WEBSITE_API_URL  + 'add-specific-illness';
export const APPLY_COUPON_URL = WEBSITE_API_URL  + 'apply-coupon';
export const CHECKOUT_URL = WEBSITE_API_URL  + 'checkout';
export const UPDATE_PAYMENT_STATUS_URL = WEBSITE_API_URL  + 'update-payment-status';
export const GET_DISTRICTS_URL = WEBSITE_API_URL  + 'get-districts';
export const GET_PLACES_URL = WEBSITE_API_URL  + 'get-places';
export const SAVE_PARTNER_URL = WEBSITE_API_URL  + 'save-partner';
export const GET_ALL_TICKETS = WEBSITE_API_URL  + 'get-all-tickets';
export const ADD_ALL_TICKETS = WEBSITE_API_URL  + 'add-ticket';
export const UPDATE_PROFILE = WEBSITE_API_URL  + 'update-profile';




