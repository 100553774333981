import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import { useEffect } from 'react';

import Pricing from './components/Pages/Pricing';
import Onboarding from './components/Pages/Onboarding';
import Benefits from './components/Pages/Benefits';
import Partner from './components/Pages/Partner';
import Profile from './components/Pages/Profile';

function App() {
  const { pathname } = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);
  
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Pricing />} />
        <Route path="onboarding" element={<Onboarding />} />
        <Route path="benefits" element={<Benefits />} />
        <Route path="partner" element={<Partner />} />
        <Route path="profile" element={<Profile />} />
      </Route>
    </Routes>
  );
}

export default App;
