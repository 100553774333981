import React from "react";
import TeamSectionStyle2 from "../Section/TeamSection/TeamSectionStyle2";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle";

export default function Doctors({ doctors }) {
  pageTitle("Doctors");
  return (
    <>
      <div
        className="col-md-12"
        style={{ textAlign: "center", marginBottom: "60px" }}
      >
        <div className="cs_section_heading cs_style_1">
          <div className="d-flex align-items-center">
            <div style={{ width: "100%", textAlign: "center" }}>
              <h2 className="cs_section_title cs_fs_50 m-0">Our Doctors</h2>
            </div>
          </div>
          <div
            style={{ height: "10px" }}
            className="cs_height_xl__ cs_height_lg__ cs_height_5"
          ></div>
          <h3 className="cs_section_subtitle cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_24">
            AVAILABLE DOCTORS
          </h3>
        </div>
      </div>
      
      <Section topMd={0} bottomMd={200} bottomLg={150} bottomXl={110}>
        <TeamSectionStyle2 doctors={doctors} />
      </Section>
    </>
  );
}
