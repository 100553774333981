import React, { useState } from 'react';
import IconBoxStyle7 from '../../IconBox/IconBoxStyle7';

export default function DepartmentSectionStyle3({ data }) { 
  const [visibleItems, setVisibleItems] = useState(6);

  const handleViewMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 6);
  };

  return (
    <div className="container cs_mt_minus_110">
      <div className="row">
        {data?.slice(0, visibleItems).map((item, index) => (
          <div className="col-xl-4 col-md-6" key={index}>
            <IconBoxStyle7 {...item} />
          </div>
        ))}
      </div>
      {visibleItems < data?.length && (
        <div className="text-center mt-4">
          <button
              type="button"
              className="cs_btn cs_style_1"
              onClick={() => handleViewMore()}
            >
              <span>View More</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </button>
        </div>
      )}
    </div>
  );
}

